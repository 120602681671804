<template>
  <div class="card">
    <div class="card-body mb-10">
      <h2 class="card-title">
        {{ $t('sghartoon.courseList') }}
      </h2>
      <div v-if="loading" class="d-flex justify-content-center mt-15 mb-15">
        <div class="spinner-border spinner-lg" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-if="!loading" class="row">
        <div class="col-sm-6" v-for="item in courseList" :key="item.id">
          <div class="card border-primary mt-6">
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">{{ item.description }}</p>
              <button
                @click="
                  () => {
                    goToCourse(item.id)
                  }
                "
                class="btn btn-primary"
              >
                {{ $t('sghartoon.watch') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ApiService from '@/core/services/ApiService'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const courseList = ref([])
const loading = ref(true)
const { t } = useI18n()
const router = useRouter()

ApiService.get(`/sghartoon/token`)
  .then(({ data }) => {
    ApiService.get(`/sghartoon/list/${data.token}`)
      .then(({ data }) => {
        courseList.value = data
        loading.value = false
      })
      .catch((e) => {
        console.log(e)
      })
  })
  .catch((e) => {
    console.log(e)
  })

onMounted(() => {
  setCurrentPageBreadcrumbs(t('sghartoon.courseList'), [])
})

function goToCourse(id) {
  router.push({
    path: '/sghartoon/video/' + id,
  })
}
</script>

<style scoped>
.border-primary {
  border: 2px solid #e6e6e6 !important;
}
</style>
